import { Box, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IAppointmentInfo } from 'Context/Types';

import React from 'react';

interface IProps {
    data: IAppointmentInfo;
    onClick: (id: string, startTime: string, endTime: string) => void;
    isSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginRight: '8px',
        width: '230px',
        fontColor: theme.palette.primary.main,
        padding: theme.spacing(1),
        border: '1px solid #DDDDDD',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primaryHover,
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.only('mobile')]: {
            width: '100%',
        },
    },
    innerBoxPadding: {
        padding: theme.spacing(2),
    },
    selected: {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primaryHover,
        color: theme.palette.primary.main,
    },
}));

const TimeCard: React.FC<IProps> = (props: IProps) => {
    const { onClick, data, isSelected } = props;
    const classes = useStyles();
    const theme = useTheme();

    const changeSelection = (info: IAppointmentInfo) => {
        onClick(info.AppointmentCode, info.AppointmentStart, info.AppointmentEnd);
        console.log(info.AppointmentCode);
    };

    return (
        <Box
            className={classes.root}
            style={{
                cursor: 'pointer',
                borderColor: isSelected ? theme.palette.primary.main : '',
                backgroundColor: isSelected ? theme.palette.primaryHover : '',
                color: isSelected ? theme.palette.primary.main : '',
            }}
        >
            <Box
                className={classes.innerBoxPadding}
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => changeSelection(data)}
            >
                <Box>
                    <Box>
                        <Typography variant="bodyRegular">{data.AppointmentStart.substring(11, 20)}</Typography>
                        <Typography variant="bodyRegular"> - </Typography>
                        <Typography variant="bodyRegular">{data.AppointmentEnd.substring(11, 20)}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TimeCard;
