import React, { createContext, useState } from 'react';
import { ICustomerInfo } from './Types';

interface IProps {
    children?: React.ReactNode;
}

interface ICustomerAction {
    setCustomerDetails: React.Dispatch<React.SetStateAction<ICustomerInfo>>;
}

const CustomerDataContext = createContext<ICustomerInfo>({} as ICustomerInfo);
const CustomerActionContext = createContext<ICustomerAction>({
    setCustomerDetails: useState,
} as ICustomerAction);

const CustomerProvider: React.FC<IProps> = (props: IProps) => {
    const [customerDetails, setCustomerDetails] = React.useState<ICustomerInfo>({} as ICustomerInfo);
    return (
        <CustomerDataContext.Provider value={customerDetails}>
            <CustomerActionContext.Provider value={{ setCustomerDetails }}>{props.children}</CustomerActionContext.Provider>
        </CustomerDataContext.Provider>
    );
};

export { CustomerActionContext, CustomerDataContext };

export default CustomerProvider;
