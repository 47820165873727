import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';
import useLoading from 'Hooks/useLoading';
import { Formik } from 'formik';
import { validationSchemaCustomer } from 'Context/ValidationSchema';
import CustomerForm from 'Components/CustomerForm';
import useResponsiveHelper from 'Hooks/useResponsiveHelper';
import SiteContent from '../SiteContent/SiteContent';
import CustomerInformationSummary from '../Components/CustomerInformationSummary';
import { IAppointmentBookRequest, ICustomerInfo, IFormState, IOtherInfo, ISiteSearchByIdResult } from '../Context/Types';

const CustomerInformation: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { loading } = useLoading();
    const response = (location.state as any)?.response as ISiteSearchByIdResult;
    const { isMobileOrTablet, isDesktop } = useResponsiveHelper();
    const [formState, setFormState] = useState<IFormState>({
        Email: '',
        FirstName: '',
        LastName: '',
        Phone: '',
        AppointmentType: 'Meter Exchange',
        DogFlag: '',
        DogFlagComment: '',
        MeterPlacement: '',
        NoteToCustomer: '',
        AdditionalComment: '',
    });

    const mappedBookAppointmentData = (values: IFormState): IAppointmentBookRequest => {
        const customerInfo = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            Email: values.Email,
            Phone: values.Phone,
        } as ICustomerInfo;
        const otherInformation = {
            AppointmentType: values.AppointmentType,
            DogFlag: values.DogFlag,
            DogFlagComment: values.DogFlagComment || 'Null',
            // AdditionalComment: values.AdditionalComment,
            // MeterPlacement: values.MeterPlacement,
        } as IOtherInfo;

        const bookingInformation = {
            Address: response.Address || '',
            AppointmentInfo: { AppointmentCode: '', AppointmentEnd: '', AppointmentStart: '' },
            Instructions: '',
            OrderNumber: response.OrderNumber || '',
            SiteId: response.SiteId || '',
        };
        const updatedData = { ...bookingInformation, CustomerInfo: customerInfo, OtherInformation: otherInformation } as IAppointmentBookRequest;
        return updatedData;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            {loading !== null && loading}
            {loading === null && (
                <Formik
                    initialValues={formState}
                    onSubmit={(values) => {
                        const appointmentData = mappedBookAppointmentData(values);
                        history.push('/home-inspection/customer-appointment', { response: appointmentData });
                    }}
                    validationSchema={validationSchemaCustomer}
                    validateOnMount={true}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                >
                    {(formikProps) => {
                        const { values, touched, errors, handleChange, handleBlur, isValid, handleSubmit } = formikProps;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                                        <Box sx={{ pl: 4, pr: 4 }}>
                                            <CustomerForm values={values} handleBlur={handleBlur} handleChange={handleChange} errors={errors} touched={touched} />
                                        </Box>
                                        {isDesktop && (
                                            <Box sx={{ marginTop: '88px', paddingRight: '32px' }}>
                                                <CustomerInformationSummary
                                                    SiteId={response.SiteId || ''}
                                                    Address={response.Address || ''}
                                                    PhoneNumber="310-5678"
                                                    OrderNumber={response.OrderNumber || ''}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                    {isMobileOrTablet && (
                                        <Box sx={{ width: '100%', paddingLeft: '32px', paddingRight: '32px', paddingBottom: '32px' }}>
                                            <Box sx={{ paddingLeft: '8px' }}>
                                                <Typography variant="bodyBold"> Other Info</Typography>
                                            </Box>
                                            <CustomerInformationSummary
                                                SiteId={response.SiteId || ''}
                                                Address={response.Address || ''}
                                                PhoneNumber="310-5678"
                                                OrderNumber={response.OrderNumber || ''}
                                            />
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', alignSelf: 'flex-start', pl: 4, pr: 4 }}>
                                        <Box sx={{ mr: 1 }}>
                                            <Button
                                                variant="submain"
                                                size="large"
                                                onClick={() => {
                                                    history.push('/');
                                                }}
                                            >
                                                <Typography variant="h2">{SiteContent.CustomerInformation.secondaryButton}</Typography>
                                            </Button>
                                        </Box>
                                        <Box>
                                            <Button variant="main" size="large" disabled={!isValid} type="submit">
                                                <Typography variant="h2"> {SiteContent.CustomerInformation.primaryButton}</Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};

export default CustomerInformation;
