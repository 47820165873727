import { Grid, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useHomeInspection from 'Hooks/useHomeInspection';
import useLoading from 'Hooks/useLoading';
import SiteContent from '../SiteContent/SiteContent';

const useStyle = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        spacing: '8px',
        flexBasis: '72%',
        [theme.breakpoints.only('desktop')]: {
            paddingLeft: '80px',
            paddingRight: '80px',
        },
        [theme.breakpoints.only('tablet')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
        },
        [theme.breakpoints.only('mobile')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    innerInput: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '21.79px',
        maxLength: 13,
        minLength: 13,
    },
}));

const Welcome: React.FC = () => {
    const [siteId, setSiteId] = useState<string>('');
    const history = useHistory();
    const classes = useStyle();
    const { searchSiteId } = useHomeInspection();
    const { showLoading, hideLoading, loading } = useLoading();
    const [siteIdError, setSitIdError] = useState<boolean>(false);
    // const { state } = useContext(IdContext);

    const onClick = async (): Promise<void> => {
        // for test purposes make into an async function when have real endpoints
        // const siteIdResponseData = InspectionAPI.getSiteId();
        // const data = { siteId } as ISiteId;
        // console.log('ID Data sent to fireBase: ', GetStatus);
        showLoading();

        try {
            const firebaseData = await searchSiteId(siteId);

            const returnData = {
                errorMessage: firebaseData.ErrorMessage,
                purpose: firebaseData.Purpose,
                servicePointNo: firebaseData.ServicePointNo,
                servicePointStatus: firebaseData.ServicePointStatus,
                status: firebaseData.Status,
                workSiteNo: firebaseData.WorkSiteNo,
                CompanyCode: firebaseData.CompanyCode,
                CollectableTypeCode: firebaseData.CollectableTypeCode,
                SiteId: firebaseData.SiteId,
                MeterOutside: firebaseData.MeterOutside,
                Address: firebaseData.Address,
                OrderNumber: firebaseData.OrderNumber,
            };
            if (returnData.status === 'S') {
                history.push('/home-inspection/customer-information', { response: returnData });
                console.log('returned returnData', returnData);
            } else {
                console.log('error submitting siteID');
                setSitIdError(true);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            hideLoading();
        }
        // test Site Id: 0001320476817
    };

    const validateSiteId = (id: string): boolean => {
        // figure out what is a valid site id to set data to true or false;

        let dissabled = false;
        const siteIdLength = 13;
        if (id.length !== siteIdLength || loading !== null) {
            dissabled = true;
        }
        return dissabled;
    };

    const onChange = (value: string): void => {
        setSiteId(value);
        setSitIdError(false);
    };

    return (
        <Grid container={true} item={true} className={classes.root}>
            <Grid item={true}>
                <Typography variant="h1">{SiteContent.welcome.title}</Typography>
            </Grid>
            <Grid item={true}>
                <Typography variant="sidebarRegular">{SiteContent.welcome.titleSmall}</Typography>
            </Grid>

            <Grid item={true} container={true} direction="column" sx={{ mt: 2, p: 1 }}>
                <Grid item={true}>
                    <Typography variant="bodyBold">Site ID</Typography>
                </Grid>
                <Grid item={true} container={true}>
                    <TextField
                        placeholder="Enter your site ID"
                        fullWidth
                        variant="outlined"
                        value={siteId}
                        disabled={loading !== null}
                        // error={!isValid && siteData.siteID.length > 3}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(event.target.value)}
                        InputProps={{
                            classes: {
                                root: classes.innerInput,
                            },
                        }}
                    />
                    {siteId.length !== 13 && siteId.length > 0 && (
                        // need to know how to flag that this is an error
                        <Grid item={true}>
                            <Typography variant="smallText" color="red">
                                Invalid Site ID - length should be 13. Your site ID should start with a number between 0001 and 0003.
                            </Typography>
                        </Grid>
                    )}
                    {siteIdError && (
                        <Typography variant="smallText" color="red">
                            Error Submitting site ID. Please make sure it was entered correctly.
                        </Typography>
                    )}
                </Grid>
                <Grid item={true} sx={{ mt: 2 }}>
                    <Button variant="main" sx={{ width: '150px' }} disabled={validateSiteId(siteId)} onClick={onClick}>
                        {loading === null && <Typography variant="h2">{SiteContent.welcome.primaryButton}</Typography>}
                        {loading !== null && <CircularProgress color="secondary" size={24} />}
                    </Button>
                </Grid>
                {/* {loading !== null && <Grid>

                </Grid>} */}
            </Grid>
        </Grid>
    );
};

export default Welcome;
