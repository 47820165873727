import React, { useEffect } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BaseTheme from './Themes/ThemeProvider';
import Layout from './Components/Layout';
import Routes from './Routes/Routes';
import './App.css';

import CustomerProvider from './Context/CustomerContext';
import useFirebaseAuth from './Pages/auth/useFirebaseAuth';

// import DefaultCustomerProvider from './Context/DefaultCustomerContext';

// pick a date util library

const App: React.FC = () => {
    const { signInAnon } = useFirebaseAuth();

    useEffect(() => {
        signInAnon();
    }, []);

    return (
        <ThemeProvider theme={BaseTheme}>
            <Router>
                <CustomerProvider>
                    <Layout>
                        <Route component={Routes} />
                    </Layout>
                </CustomerProvider>
            </Router>
        </ThemeProvider>
    );
};

export default App;
