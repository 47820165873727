import * as yup from 'yup';

export const validationSchemaCustomer = yup.object({
    FirstName: yup.string().required('First Name is required'),
    LastName: yup.string().required('Last Name is required'),
    Phone: yup
        .string()
        .matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, 'Please enter a valid Phone Number')
        .required('Phone number is required'),
    // alternatePhoneNumber: yup.string(),
    Email: yup.string().email('Enter a valid email').required('Please enter a valid email address'),
    DogFlag: yup.string().required('Required field'),
    // MeterPlacement: yup.string().required('Required field'),
});
