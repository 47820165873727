import React from 'react';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { IAppointmentBookRequest, IAppointmentInfo, IBookAppointmentResponse, ISearchAppointmentRequest, ISearchAppointmentResponse, ISiteIdResponse, ISiteSearchByIdResult } from 'Context/Types';
import { defaultAppointmentBokResult, defaultAppointmentSearchResult2 } from 'API/InspectionDefaults';
import { functions } from '../API/firebase';

const BookAppointment = httpsCallable(functions, 'bookAppointment', { timeout: 200000 });
const SearchSiteId = httpsCallable(functions, 'searchSiteId', { timeout: 200000 });
const SearchAppointment = httpsCallable(functions, 'searchAppointment', { timeout: 200000 });

const useHomeInspection = () => {
    const bookApppointment = async (appointmentBooking: IAppointmentBookRequest): Promise<IBookAppointmentResponse> => {
        // const appointment = await BookAppointment({ appointmentBooking });
        // const parsedData = JSON.parse(JSON.stringify(appointment.data));
        const parsedData = defaultAppointmentBokResult;
        return parsedData as IBookAppointmentResponse;
    };

    const searchSiteId = async (siteId: string): Promise<ISiteSearchByIdResult> => {
        const siteSearchResults = await SearchSiteId({ siteId });
        const parsedData = JSON.parse(JSON.stringify(siteSearchResults.data));
        return parsedData as ISiteSearchByIdResult;
    };

    const searchAppointment = async (appointmentRequest: ISearchAppointmentRequest): Promise<ISearchAppointmentResponse> => {
        // const searchedAppointments = await SearchAppointment({ appointmentRequest });
        // const parsedData = JSON.parse(JSON.stringify(searchedAppointments.data));
        const parsedData = defaultAppointmentSearchResult2;

        return parsedData as ISearchAppointmentResponse;
    };
    return { bookApppointment, searchSiteId, searchAppointment } as const;
};

export default useHomeInspection;
