import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, CircularProgress, Box, Checkbox } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { IAppointmentBookRequest, IAppointmentInfo, IDayTimeMatrix, IOtherInfo, ISearchAppointmentRequest } from 'Context/Types';
import useLoading from 'Hooks/useLoading';
import { defaultAppointmentInfo } from 'API/InspectionDefaults';
import useHomeInspection from 'Hooks/useHomeInspection';
import { makeStyles } from '@mui/styles';
import useResponsiveHelper from 'Hooks/useResponsiveHelper';
import SiteContent from '../SiteContent/SiteContent';
import CustomerInformationSummary from '../Components/CustomerInformationSummary';
import AppointmentGrid from '../Components/AppointmentGrid';

const useStyle = makeStyles((theme) => ({
    root: {
        paddingLeft: '32px',
        marginTop: '32px',
        [theme.breakpoints.only('mobile')]: {
            display: 'flex',
            flexDirection: 'column',

            paddingRight: '32px',
        },
    },
    subTitle: {
        paddingRight: '20%',
        [theme.breakpoints.only('mobile')]: {
            paddingRight: '0px',
        },
    },
    selectDay: {
        width: '180px',
        paddingBottom: '16px',
        [theme.breakpoints.only('mobile')]: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            paddingRight: '0px',
        },
    },
    appointmentGrid: {
        display: 'flex',
        flexDirection: 'column',
        spacing: '8px',
        flexBasis: '72%',

        [theme.breakpoints.only('desktop')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        [theme.breakpoints.only('tablet')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        [theme.breakpoints.only('mobile')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    customerInformationSummary: {
        marginLeft: '50px',
        flexBasis: '28%',
        paddingRight: '32px',
    },
    dateAndOtherInfoGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
}));

const Appointment: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyle();
    const { searchSiteId } = useHomeInspection();
    const { searchAppointment, bookApppointment } = useHomeInspection();
    const { showLoading, hideLoading, loading } = useLoading();
    const [availableTimes, setAvailableTimes] = useState<IDayTimeMatrix[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [selectedTime, setSelectedTime] = useState<IAppointmentInfo>(defaultAppointmentInfo);
    const { isMobileOrTablet, isDesktop } = useResponsiveHelper();
    const callBackOptions = ['Yes, call me when you are on your way', 'No, do not call me when you are on your way'];
    const [callBack, setcallBack] = useState<string>(callBackOptions[1]);
    const emailOptions = ['Yes, I want an email reminder', 'No, I do not need a reminder'];
    const [emailBack, setEmailBack] = useState<string>(emailOptions[1]);

    const response = (location.state as any)?.response as IAppointmentBookRequest;

    const addFiveDays = (date: string): Date => {
        const parsedDate = new Date(date);
        const resultDate = new Date(parsedDate.setDate(parsedDate.getDate() + 5));
        return resultDate;
    };

    const filterAvailableTimes = (date: string, timeOptions: IAppointmentInfo[]): IAppointmentInfo[] => {
        const tempDate = new Date(date);
        const startDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
        const endDate = addFiveDays(date);

        const filteredTimes = timeOptions.filter((time) => {
            const tempCheckDate = new Date(time.AppointmentStart.substring(0, 10));
            const dateCheck = new Date(tempCheckDate.setDate(tempCheckDate.getDate() + 1));
            const isWIthinTImeFrame = dateCheck <= endDate && dateCheck >= startDate;

            return isWIthinTImeFrame;
        }) as IAppointmentInfo[];

        return filteredTimes;
    };

    const mappedtimes = (unfilteredTimes: IAppointmentInfo[]) => {
        const filteredStartTimes = unfilteredTimes.map((times) => {
            const starttime = times.AppointmentStart.substring(0, 10); // get a list of times can be duplicates
            return starttime;
        });
        const uniqueStartTimes = [...Array.from(new Set(filteredStartTimes))]; // casting anything to set will remove duplicates and only unique values
        const mappedTimes = uniqueStartTimes.map((startTime) => {
            const mappedTime = { day: startTime, appointments: unfilteredTimes.filter((time) => time.AppointmentStart.includes(startTime)) } as IDayTimeMatrix;
            return mappedTime;
        });
        return mappedTimes;
    };

    const handleChange = async (date: string): Promise<void> => {
        const mappedAppointmentRequest = {
            Address: response.Address,
            AppointmentInfo: {
                AppointmentCode: 'T',
                AppointmentStart: date,
                AppointmentEnd: addFiveDays(date).toString(),
            },
            OtherInformation: response.OtherInformation,
            CustomerInfo: response.CustomerInfo,
            OrderNumber: response.OrderNumber,
            SiteId: response.SiteId,
        } as ISearchAppointmentRequest;

        const searchAppointmentResponse = await searchAppointment(mappedAppointmentRequest);
        setInitialLoad(false);

        if (searchAppointmentResponse.Status === 'S') {
            setAvailableTimes(mappedtimes(searchAppointmentResponse.AppointmentInfo));
            setSelectedDate(date);
        } else {
            console.log('DID NOT find any successful times');
        }
    };

    const onClick = (id: string, startTime: string, endTime: string): void => {
        const selectedAppointment = { AppointmentCode: id, AppointmentStart: startTime, AppointmentEnd: endTime };
        setSelectedTime(selectedAppointment);
    };

    const mappedTotalData = (values: IAppointmentInfo): IAppointmentBookRequest => {
        const appointmentInfo = {
            AppointmentCode: values.AppointmentCode,
            AppointmentStart: values.AppointmentStart,
            AppointmentEnd: values.AppointmentEnd,
        } as IAppointmentInfo;
        const otherInfo = {
            ...response.OtherInformation,
            AdditionalComment: `${emailBack}. ${callBack}`,
        } as IOtherInfo;
        const updatedData = {
            ...response,
            AppointmentInfo: appointmentInfo,
            OtherInformation: otherInfo,
        };
        return updatedData;
    };

    const backButtonClick = async (): Promise<void> => {
        // for test purposes make into an async function when have real endpoints
        // const siteIdResponseData = InspectionAPI.getSiteId();
        // const data = { siteId } as ISiteId;
        // console.log('ID Data sent to fireBase: ', GetStatus);
        showLoading();

        try {
            const firebaseData = await searchSiteId(response.SiteId);

            const returnData = {
                errorMessage: firebaseData.ErrorMessage,
                purpose: firebaseData.Purpose,
                servicePointNo: firebaseData.ServicePointNo,
                servicePointStatus: firebaseData.ServicePointStatus,
                status: firebaseData.Status,
                workSiteNo: firebaseData.WorkSiteNo,
                CompanyCode: firebaseData.CompanyCode,
                CollectableTypeCode: firebaseData.CollectableTypeCode,
                SiteId: firebaseData.SiteId,
                MeterOutside: firebaseData.MeterOutside,
                Address: firebaseData.Address,
                OrderNumber: firebaseData.OrderNumber,
            };
            if (returnData.status === 'S') {
                history.push('/home-inspection/customer-information', { response: returnData });
                console.log('returned returnData', returnData);
            } else {
                console.log('error submitting siteID');
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            hideLoading();
        }
    };

    return (
        <>
            {loading !== null && loading}
            {loading === null && (
                <>
                    <Grid className={classes.root}>
                        <Grid item={true}>
                            <Typography variant="h1">{SiteContent.CustomerAppointment.title}</Typography>
                        </Grid>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Box>
                                <Box className={classes.subTitle}>
                                    <Typography variant="sidebarRegular">{SiteContent.CustomerAppointment.subTitle}</Typography>
                                </Box>
                                <Grid item={true} container={true} className={classes.selectDay}>
                                    <Grid sx={{ marginTop: '32px', marginBottom: '8px' }}>
                                        <Typography variant="bodyBold"> Select a timeslot</Typography>
                                    </Grid>
                                    <Box sx={{ textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                        <TextField
                                            fullWidth
                                            id="date"
                                            type="date"
                                            onChange={async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event.target.value)}
                                            variant="outlined"
                                            inputProps={{ min: new Date().toISOString().slice(0, 10) }}
                                        />
                                    </Box>
                                </Grid>
                            </Box>
                            <Box>
                                {isDesktop && (
                                    <Grid container className={classes.customerInformationSummary}>
                                        <CustomerInformationSummary SiteId={response.SiteId || ''} Address={response.Address || ''} PhoneNumber="310-5678" OrderNumber={response.OrderNumber || ''} />
                                    </Grid>
                                )}
                            </Box>
                        </Box>

                        <Grid container={true} item={true} className={classes.appointmentGrid}>
                            <Grid container={true} item={true}>
                                <Grid item container flexDirection="column" spacing={2}>
                                    {availableTimes.length === 0 && loading === null && initialLoad !== true && (
                                        <Box sx={{ paddingLeft: '16px', paddingTop: '16px' }}>
                                            <Typography variant="caption" color="red">
                                                **No time slots available, please select a new service date from the calendar**
                                            </Typography>
                                        </Box>
                                    )}
                                    {loading !== null && <CircularProgress color="secondary" size={50} />}
                                    {availableTimes.map((time) => (
                                        <AppointmentGrid data={time} onClick={onClick} selectedTime={selectedTime} />
                                    ))}
                                </Grid>
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                    <Grid item={true}>
                                        <Typography variant="bodyBold">Reminders</Typography>
                                    </Grid>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-8px' }}>
                                        <Checkbox
                                            name="AdditionalComment"
                                            checked={emailBack === emailOptions[0]}
                                            color="primary"
                                            value={emailBack}
                                            onClick={() => {
                                                if (emailBack === emailOptions[0]) {
                                                    setEmailBack(emailOptions[1]);
                                                } else {
                                                    setEmailBack(emailOptions[0]);
                                                }
                                            }}
                                        />
                                        <Grid item={true}>
                                            <Typography variant="sidebarRegular" style={{ color: '#000' }}>
                                                Email me a reminder 2 days before my appointment.
                                            </Typography>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-8px' }}>
                                        <Checkbox
                                            name="AdditionalComment"
                                            checked={callBack === callBackOptions[0]}
                                            color="primary"
                                            value={callBack}
                                            onClick={() => {
                                                if (callBack === callBackOptions[0]) {
                                                    setcallBack(callBackOptions[1]);
                                                } else {
                                                    setcallBack(callBackOptions[0]);
                                                }
                                            }}
                                        />
                                        <Grid item={true}>
                                            <Typography variant="sidebarRegular" style={{ color: '#000' }}>
                                                Call me when you are on your way.
                                            </Typography>
                                        </Grid>
                                    </Box>
                                </Box>

                                {isMobileOrTablet && (
                                    <Box sx={{ width: '100%', paddingTop: '32px', paddingRight: '32px' }}>
                                        <Box sx={{ paddingLeft: '8px', marginBottom: '8px' }}>
                                            <Typography variant="bodyBold"> Other Info</Typography>
                                        </Box>
                                        <CustomerInformationSummary SiteId={response.SiteId || ''} Address={response.Address || ''} PhoneNumber="310-5678" OrderNumber={response.OrderNumber || ''} />
                                    </Box>
                                )}

                                <Grid item={true} container={true} style={{ marginTop: '16px' }} wrap="nowrap">
                                    <Grid item={true}>
                                        <Button
                                            disabled={loading !== null}
                                            variant="submain"
                                            size="large"
                                            onClick={() => {
                                                backButtonClick();
                                            }}
                                        >
                                            <Typography variant="h2">{SiteContent.CustomerInformation.secondaryButton}</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item={true} marginLeft="10px">
                                        <Button
                                            variant="main"
                                            size="large"
                                            disabled={selectedTime.AppointmentCode === '' || loading !== null}
                                            onClick={async () => {
                                                showLoading();
                                                try {
                                                    const appointmentRequestData = mappedTotalData(selectedTime);

                                                    const firebaseData = await bookApppointment(appointmentRequestData);

                                                    history.push('/home-inspection/thank-you', { response: appointmentRequestData });
                                                } catch (error) {
                                                    console.log('error', error);
                                                } finally {
                                                    hideLoading();
                                                }
                                            }}
                                        >
                                            {loading == null && <Typography variant="h2"> {SiteContent.CustomerInformation.submitButton}</Typography>}
                                            {loading !== null && <CircularProgress color="secondary" size={24} />}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default Appointment;
