import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from '@mui/material';
import useFirebaseAuth from 'Pages/auth/useFirebaseAuth';
import useLoading from 'Hooks/useLoading';
import Welcome from '../Pages/Welcome';
import CustomerInformation from '../Pages/CustomerInformation';
import Appointment from '../Pages/Appointment';
import Thankyou from '../Pages/Thankyou';

const Routes: React.FC = () => {
    const { signInAnon } = useFirebaseAuth();
    const [hasSignedIn, setHasSignedIn] = React.useState<boolean>(false);
    const { loading, showLoading, hideLoading } = useLoading();

    const renderRoutes = (signedIn: boolean): JSX.Element | null => {
        let component = loading;

        if (signedIn) {
            if (loading === null) {
                component = (
                    <Box display="flex" flexDirection="column">
                        <Route component={Routes} />
                    </Box>
                );
            }
        }
        return component;
    };

    return (
        <Switch>
            <Route exact={true} path="/" component={Welcome} />
            <Route exact={true} path="/home-inspection/customer-information" component={CustomerInformation} />
            <Route exact={true} path="/home-inspection/customer-appointment" component={Appointment} />
            <Route exact={true} path="/home-inspection/thank-you" component={Thankyou} />
        </Switch>
    );
};

export default Routes;
