import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, Theme, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IFormState } from 'Context/Types';
import React, { useState } from 'react';
import SiteContent from 'SiteContent/SiteContent';

interface IProps {
    values: IFormState;
    handleChange: (e: any) => void;
    handleBlur: (e: any) => void;
    errors: any;
    touched: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        marginTop: theme.spacing(10),
        flexBasis: '72%',
        height: '100%',
    },
    innerInput: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '21.79px',
    },
}));

const CustomerForm = (props: IProps) => {
    const { values, errors, touched, handleChange, handleBlur } = props;
    const classes = useStyles();

    return (
        <Grid container={true} item={true} className={classes.container}>
            <Grid item={true}>
                <Typography variant="h1">{SiteContent.CustomerInformation.title}</Typography>
            </Grid>
            <Grid item={true}>
                <Typography variant="sidebarRegular">{SiteContent.CustomerInformation.subTitle}</Typography>
            </Grid>

            <Grid item={true} container={true} direction="column" spacing={1} sx={{ mt: 2 }}>
                <Grid container={true} item={true} spacing={1}>
                    <Grid item={true}>
                        <Typography variant="bodyBold">First Name</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter your first name"
                            variant="outlined"
                            name="FirstName"
                            value={values.FirstName}
                            error={!!errors.FirstName && !!touched.FirstName}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.FirstName ? errors.FirstName : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} item={true} spacing={1} marginTop="10px">
                    <Grid item={true}>
                        <Typography variant="bodyBold">Last Name</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter in your last name"
                            variant="outlined"
                            name="LastName"
                            value={values !== undefined ? values.LastName : ''}
                            error={!!errors.LastName && !!touched.LastName}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.LastName ? errors.LastName : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={1} marginTop="10px">
                    <Grid item={true}>
                        <Typography variant="bodyBold">Phone Number</Typography>
                    </Grid>
                    <Grid item={true} container={true} flexDirection="column">
                        <TextField
                            placeholder="Please enter your phone number"
                            name="Phone"
                            error={!!errors.Phone && !!touched.Phone}
                            fullWidth
                            value={values !== undefined ? values.Phone : ''}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.Phone ? errors.Phone : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                        <Typography variant="smallText">ATCO will only attempt to call this number if there is a problem with the appointment, or if you have requested a call ahead.</Typography>
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={1} marginTop="10px">
                    <Grid item={true}>
                        <Typography variant="bodyBold">Email Address</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter your email address"
                            name="Email"
                            value={values !== undefined ? values.Email : ''}
                            onChange={handleChange}
                            error={!!errors.Email && !!touched.Email}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.Email ? errors.Email : ''}
                                </Typography>
                            }
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item={true} marginTop="16px">
                    <Typography variant="bodyBold">Is There a Dog on Site?</Typography>
                </Grid>
                <Grid item={true} container={true} sx={{ display: 'flex' }}>
                    <RadioGroup row={true} aria-label="gender" name="DogFlag" value={values.DogFlag} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {values.DogFlag === 'Yes' && (
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter any additional comments about the dog"
                            name="DogFlagComment"
                            value={values !== undefined ? values.DogFlagComment : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                        <Typography variant="smallText">Optional</Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export interface IOtherInfo {
    AppointmentType: string;
    DogFlag: string;
    DogFlagComment: string;
    MeterPlacement: string;
    NoteToCustomer: string;
    AdditionalComment: string;
}

export default CustomerForm;
