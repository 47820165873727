import React from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Box, Toolbar, Typography, Theme, useMediaQuery, useTheme } from '@mui/material';
import useResponsiveHelper from 'Hooks/useResponsiveHelper';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(2),
        justifyContent: 'space-between',
        width: '100%',
    },
    logoImageContainer: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(8),
        alignItems: 'flex-start',
        display: 'inline-flex',
        [theme.breakpoints.down('tablet')]: {
            paddingLeft: theme.spacing(0),
        },
    },
    logoImage: {
        height: theme.spacing(5),
        alignSelf: 'flex-start',
        objectFit: 'contain',
        marginBottom: theme.spacing(1),
    },
    titleAndNumberContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
        paddingRight: theme.spacing(9),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.only('tablet')]: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: theme.spacing(9),
            paddingLeft: '16px',
            alignItems: 'flex-end',
        },
        [theme.breakpoints.down('tablet')]: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: theme.spacing(0),
            paddingLeft: '16px',
            alignItems: 'flex-end',
        },
    },
    dividerContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        height: '40%',
    },
}));

const NavBar: React.FC = () => {
    const classes = useStyles();
    const { isMobileOrTablet, isDesktop } = useResponsiveHelper();

    return (
        <AppBar position="fixed" elevation={0} style={{ maxHeight: '96px' }}>
            <Toolbar className={classes.root}>
                <Box className={classes.logoImageContainer}>
                    <img className={classes.logoImage} src="/images/VersionWhiteLogo@1x.png" alt="imge" title="ATCO logo" />
                </Box>
                <Box className={classes.titleAndNumberContainer}>
                    <Typography variant="h2">Hydrogen Blending Home Inspections</Typography>
                    {isDesktop && (
                        <Box className={classes.dividerContainer}>
                            <hr style={{ borderColor: 'white', height: '24px' }} />
                        </Box>
                    )}

                    <Box>
                        <Typography variant="h2">780-733-2591</Typography>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
