import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getAuth, signInAnonymously } from 'firebase/auth';
// import firebase from '../node_modules/firebase/index/firebase';

const firebaseConfig = {
    apiKey: 'AIzaSyCYeGA69psZZuRY-YzQdNXglfcxo9rMT7A',
    authDomain: 'in-home-inspection.firebaseapp.com',
    projectId: 'in-home-inspection',
    storageBucket: 'in-home-inspection.appspot.com',
    messagingSenderId: '911620879700',
    appId: '1:911620879700:web:05be37f9419a2d164244c1',
    measurementId: 'G-LNK0QN234K',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const functions = getFunctions(app);
export const anonSignIn = async () => signInAnonymously(auth);
