import React from 'react';
import { CssBaseline, Grid, useTheme } from '@mui/material';
import Navbar from './Navbar';

interface IProps {
    children?: React.ReactNode;
}

const Layout: React.FC<IProps> = (props: IProps) => {
    const theme = useTheme();
    return (
        <>
            <CssBaseline />
            <Grid container={true} direction="column" alignItems="center" justifyContent="space-between" wrap="nowrap" minHeight="100vh">
                <Grid item={true} container={true} flex={1} maxHeight="96px">
                    <Navbar />
                </Grid>
                <Grid item={true} container={true} marginTop="80px" marginBottom="32px" justifyContent="center">
                    {props.children}
                </Grid>
                <Grid item={true} flex={1} marginTop="auto" container={true} bottom={0} minHeight="32px" maxHeight="72px" style={{ backgroundColor: theme.palette.primary.main }} />
            </Grid>
        </>
    );
};

export default Layout;
