import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IAppointmentBookRequest, IAppointmentInfo, IDayTimeMatrix } from 'Context/Types';

import React from 'react';
import TimeCard from './TimeCard';

const useStyle = makeStyles((theme) => ({
    timeCard: {
        display: 'flex',
        marginBottom: '8px',
        [theme.breakpoints.only('mobile')]: {
            width: '100%',
        },
    },
}));

interface IProps {
    data: IDayTimeMatrix;
    onClick: (id: string, startTime: string, endTime: string) => void;
    selectedTime: IAppointmentInfo;
}

const formatAppointmentTime = (data: string) => {
    const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const year = data.substring(0, 4);
    const month = data.substring(5, 7);
    const monthWord = monthArray[parseInt(month, 10) - 1];
    const day = data.substring(8, 10);
    const outputString = `${monthWord}.${day} / ${year}`;
    return outputString;
};

const AppointmentGrid: React.FC<IProps> = (props: IProps) => {
    const { onClick, data, selectedTime } = props;
    const classes = useStyle();
    return (
        <Box sx={{ marginLeft: '16px' }}>
            <Typography sx={{ marginTop: '16px' }}>{formatAppointmentTime(data.day)}</Typography>
            <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                {data.appointments.map((time) => (
                    <Box className={classes.timeCard}>
                        <TimeCard data={time} onClick={onClick} isSelected={time.AppointmentCode === selectedTime?.AppointmentCode} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default AppointmentGrid;
