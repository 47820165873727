import React, { createContext } from 'react';
import { IAppointmentBookRequest, IAppointmentInfo, IBookAppointmentResponse, ISearchAppointmentResponse, ISiteSearchByIdResult } from 'Context/Types';

export const defaultSiteSearchResult = {
    ErrorMessage: '',
    Purpose: '',
    ServicePointNo: '1234',
    ServicePointStatus: '',
    Status: '',
    WorkSiteNo: '7654321',
    // companyCode: '1234567',
    // collectableTypeCode: '',
    // siteId: '0001046042030',
    // meterOutside: '',
    // address: 'AdamsHouse',
    // orderNumber: '2468',
} as ISiteSearchByIdResult;

export const defaultAppointmentInfo = {
    AppointmentCode: '',
    AppointmentEnd: '',
    AppointmentStart: '',
};

export const defaultAppointmentInfoResults = [
    { AppointmentCode: '1', AppointmentStart: '2021-12-01-10:00 AM', AppointmentEnd: '2021-12-01-10:30 AM' },
    { AppointmentCode: '2', AppointmentStart: '2021-12-01-11:00 AM', AppointmentEnd: '2021-12-01-11:30 AM' },
    { AppointmentCode: '3', AppointmentStart: '2021-12-01-11:30 AM', AppointmentEnd: '2021-12-01-01:30 PM' },
    { AppointmentCode: '4', AppointmentStart: '2021-12-02-10:00 AM', AppointmentEnd: '2021-12-02-10:30 AM' },
    { AppointmentCode: '5', AppointmentStart: '2021-12-02-11:00 AM', AppointmentEnd: '2021-12-02-11:30 AM' },
    { AppointmentCode: '6', AppointmentStart: '2021-12-02-11:30 AM', AppointmentEnd: '2021-12-02-01:30 PM' },
    { AppointmentCode: '7', AppointmentStart: '2021-12-03-10:00 AM', AppointmentEnd: '2021-12-03-10:30 AM' },
    { AppointmentCode: '8', AppointmentStart: '2021-12-03-11:00 AM', AppointmentEnd: '2021-12-03-11:30 AM' },
    { AppointmentCode: '9', AppointmentStart: '2021-12-03-11:30 AM', AppointmentEnd: '2021-12-03-01:30 PM' },
    { AppointmentCode: '10', AppointmentStart: '2021-12-04-11:00 AM', AppointmentEnd: '2021-12-04-11:30 AM' },
    { AppointmentCode: '11', AppointmentStart: '2021-12-04-11:30 AM', AppointmentEnd: '2021-12-04-01:30 PM' },
    { AppointmentCode: '12', AppointmentStart: '2021-12-05-11:30 AM', AppointmentEnd: '2021-12-05-01:30 PM' },
    { AppointmentCode: '13', AppointmentStart: '2021-12-05-11:00 AM', AppointmentEnd: '2021-12-05-11:30 AM' },
    { AppointmentCode: '14', AppointmentStart: '2021-12-05-11:30 AM', AppointmentEnd: '2021-12-05-01:30 PM' },
    { AppointmentCode: '15', AppointmentStart: '2021-12-05-01:30 PM', AppointmentEnd: '2021-12-05-02:30 PM' },
    { AppointmentCode: '16', AppointmentStart: '2021-12-05-02:00 PM', AppointmentEnd: '2021-12-05-03:30 PM' },
    { AppointmentCode: '17', AppointmentStart: '2021-12-05-03:30 PM', AppointmentEnd: '2021-12-05-04:30 PM' },
] as IAppointmentInfo[];

export const defaultAppointmentSearchResult2 = {
    SiteId: '0001046042030',
    Status: 'S',
    OrderNumber: 'MX8651309',
    AppointmentInfo: defaultAppointmentInfoResults,
} as ISearchAppointmentResponse;

export const defaultAppointmentBokResult = {
    Status: 'S',
} as IBookAppointmentResponse;

const IdContext = createContext<{ state: ISiteSearchByIdResult }>({
    state: defaultSiteSearchResult,
});
export default IdContext;
