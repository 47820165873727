import React, { useEffect } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import useLoading from 'Hooks/useLoading';

import { makeStyles } from '@mui/styles';
import useResponsiveHelper from 'Hooks/useResponsiveHelper';
import SiteContent from '../SiteContent/SiteContent';
import { IAppointmentBookRequest } from '../Context/Types';

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        spacing: '8px',
        [theme.breakpoints.only('desktop')]: {
            paddingLeft: '80px',
            paddingRight: '80px',
        },
        [theme.breakpoints.only('tablet')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
        },
        [theme.breakpoints.only('mobile')]: {
            paddingLeft: '32px',
            paddingRight: '32px',
        },
    },
    titleAndPrintButton: {
        [theme.breakpoints.up('tablet')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('tablet')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    clientInfoDisplay: {
        marginTop: '10px',
        [theme.breakpoints.up('tablet')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        [theme.breakpoints.down('tablet')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    printButton: {
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.up('tablet')]: {
            paddingBottom: '0px',
            paddingTop: '0px',
        },
        [theme.breakpoints.down('tablet')]: {
            paddingBottom: '16px',
            paddingTop: '16px',
        },
    },
}));

const Thankyou: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyle();
    const { loading } = useLoading();
    const { isMobile, isTabletOrDesktop } = useResponsiveHelper();
    const response = (location.state as any)?.response as IAppointmentBookRequest;

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.replace('/');
            }
        };
    }, [history]);

    const formatArivalTime = (data: IAppointmentBookRequest) => {
        const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const year = data.AppointmentInfo.AppointmentStart.substring(0, 4);
        const month = data.AppointmentInfo.AppointmentStart.substring(5, 7);
        const monthWord = monthArray[parseInt(month, 10) - 1];
        const day = data.AppointmentInfo.AppointmentStart.substring(8, 10);
        const time = data.AppointmentInfo.AppointmentStart.substring(11, 20);
        const outputString = `${monthWord} ${day} ${year} at ~${time}`;
        return outputString;
    };

    return (
        <>
            {loading !== null && loading}
            {loading === null && (
                <Grid container={true} item={true} className={classes.root}>
                    <Grid item={true}>
                        {/* {isTabletOrDesktop && ( */}
                        <Box className={classes.titleAndPrintButton}>
                            {isTabletOrDesktop && (
                                <Box>
                                    <Typography variant="h1">{SiteContent.Thankyou.title}</Typography>
                                </Box>
                            )}
                            {isMobile && (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box>
                                        <Typography variant="h1">THANK YOU!</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h1">YOUR APPOINTMENT IS CONFIRMED!</Typography>
                                    </Box>
                                </Box>
                            )}
                            <Box className={classes.printButton}>
                                <Button
                                    endIcon={<PrintIcon />}
                                    variant="submain"
                                    size="large"
                                    onClick={() => {
                                        window.print();
                                    }}
                                >
                                    <Typography variant="h2">Print</Typography>
                                </Button>
                            </Box>
                        </Box>
                        {/* )} */}
                    </Grid>
                    <Grid item={true}>
                        <Typography variant="sidebarRegular">{SiteContent.Thankyou.subTitle}</Typography>
                    </Grid>
                    <Box sx={{ marginTop: '20px' }}>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Confirmation Number</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">MX11142312</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Order Number</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.OrderNumber}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Arrival Time</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular"> {formatArivalTime(response)} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Appointment Type</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.OtherInformation?.AppointmentType}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Site ID</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.SiteId}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Service Address</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.Address}</Typography>{' '}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">First Name</Typography>
                            </Grid>
                            <Grid item={true}>
                                {' '}
                                <Typography variant="bodyRegular">{response.CustomerInfo.FirstName}</Typography>{' '}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Last Name</Typography>
                            </Grid>
                            <Grid item={true}>
                                {' '}
                                <Typography variant="bodyRegular">{response.CustomerInfo.LastName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Phone Number</Typography>
                            </Grid>
                            <Grid item={true}>
                                {' '}
                                <Typography variant="bodyRegular">{response.CustomerInfo.Phone}</Typography>{' '}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Email Address</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.CustomerInfo.Email}</Typography>{' '}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Is there a dog in the yard/ on the property?</Typography>
                            </Grid>
                            <Grid item={true}>
                                {' '}
                                <Typography variant="bodyRegular">
                                    {' '}
                                    <Typography variant="bodyRegular">{response.OtherInformation?.DogFlag}</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Dog Comment </Typography>
                            </Grid>
                            <Grid item={true}>
                                {' '}
                                <Typography variant="bodyRegular">{response.OtherInformation?.DogFlagComment}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Email Reminder </Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.OtherInformation?.AdditionalComment.substring(0, 30)}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Call When On The Way </Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.OtherInformation?.AdditionalComment.substring(30, 80)}.</Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid container className={classes.clientInfoDisplay}>
                            <Grid item={true}>
                                <Typography variant="responsiveBodyText">Meter Placement</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography variant="bodyRegular">{response.OtherInformation?.MeterPlacement}</Typography>
                            </Grid>
                        </Grid> */}

                        <Grid item={true} container={true} style={{ marginTop: '16px' }} wrap="nowrap">
                            <Grid width="100%" paddingRight="16px">
                                <Typography variant="sidebarRegular">To cancel or reschedule your appointment, please call 780-733-2591 at least 24 hours prior to your appointment.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item={true} container={true} style={{ marginTop: '8px' }} wrap="nowrap">
                            <Grid item={true} width="100%">
                                <Typography variant="sidebarRegular">For more information regarding the Fort Saskatchewan Hydrogen Blending Project visit </Typography>
                                <Typography
                                    variant="addEditLink"
                                    borderBottom="0.5px solid #0057B8"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => window.open('https://www.atco.com/hydrogenblending', '_blank')}
                                >
                                    www.atco.com/hydrogenblending{' '}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item={true} container={true} style={{ marginTop: '16px' }} wrap="nowrap">
                            <Grid item={true} width="50%">
                                <Button
                                    variant="main"
                                    size="large"
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                >
                                    <Typography variant="h2"> Return to Main Page </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default Thankyou;
