/* eslint-disable no-console */
import { anonSignIn } from 'API/firebase';
import { signInAnonymously } from 'firebase/auth';
import React from 'react';

const useFirebaseAuth = () => {
    const signInAnon = async (): Promise<void> => {
        await anonSignIn()
            .then(() => {
                console.log('success');
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return { signInAnon };
};
export default useFirebaseAuth;
