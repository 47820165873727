import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Grid, Typography, Theme } from '@mui/material';

interface IProps {
    SiteId: string;
    OrderNumber: string;
    Address: string;
    PhoneNumber: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(2),
        [theme.breakpoints.only('desktop')]: {
            width: 308,
        },

        [theme.breakpoints.down('desktop')]: {
            width: '100%',
        },
    },
}));

const CustomerInformationSummary = (props: IProps) => {
    const classes = useStyles();

    return (
        <Box>
            <Card className={classes.root} elevation={0}>
                <Grid container={true} flexDirection="column" sx={{ pb: 1 }}>
                    <Typography variant="bodyBold">Site Id</Typography>
                    <Typography variant="bodyRegular"> {props.SiteId} </Typography>
                </Grid>
                <Grid container={true} flexDirection="column" sx={{ pb: 1 }}>
                    <Typography variant="bodyBold">Order Number</Typography>
                    <Typography variant="bodyRegular"> {props.OrderNumber} </Typography>
                </Grid>
                <Grid container={true} flexDirection="column" sx={{ pb: 1 }}>
                    <Typography variant="bodyBold">Work Site Number</Typography>
                    <Typography variant="bodyRegular"> No Data in Backend yet </Typography>
                </Grid>
                <Grid container={true} flexDirection="column" sx={{ pb: 1 }}>
                    <Typography variant="bodyBold">Site Address</Typography>
                    <Typography variant="bodyRegular"> {props.Address} </Typography>
                    <Typography variant="smallText">
                        Not your Address? Call us at
                        <Typography variant="smallText" color="primary">
                            {'  '}
                            {props.PhoneNumber}
                        </Typography>
                    </Typography>
                </Grid>
                {/* <Grid container={true} flexDirection="column" paddingBottom="10px">
                    <Typography variant="bodyBold">Meter Location</Typography>
                    <Typography variant="bodyRegular"> {siteData.address.meterLocation} </Typography>
                </Grid> */}
                {/* {stepperData.activeStep === Steps.appointment && (
                    <Grid container={true} flexDirection="column" paddingBottom="10px">
                        <Typography variant="bodyBold">Relight Required</Typography>
                        <Typography variant="bodyRegular"> {customerData.customerDetails.isRelightRequired.value} </Typography>
                    </Grid>
                )} */}
                {/* {customerData.appointment.date !== '' && (
                    <Grid container={true} flexDirection="column" paddingBottom="10px">
                        <Typography variant="bodyBold">Service Date and Time</Typography>
                        <Typography variant="bodyRegular"> {`${moment(customerData.appointment.date).format('LL')} between ${customerData.appointment.timeSlot}`} </Typography>
                    </Grid>
                )} */}
                {/* {stepperData.activeStep === Steps.appointment && (
                    <Grid>
                        <Grid container style={{ backgroundColor: theme.palette.grey[300] }} paddingLeft={theme.spacing(1)} paddingRight={theme.spacing(1)} marginTop="10px" height="1px" />
                        <Grid container={true} flexDirection="column" paddingBottom="10px" paddingTop="10px">
                            <Typography variant="bodyBold">First Name</Typography>
                            <Typography variant="bodyRegular"> {customerData.customerDetails.firstName.value} </Typography>
                        </Grid>
                        <Grid container={true} flexDirection="column" paddingBottom="10px">
                            <Typography variant="bodyBold">Last Name</Typography>
                            <Typography variant="bodyRegular"> {customerData.customerDetails.lastName.value} </Typography>
                        </Grid>
                        <Grid container={true} flexDirection="column" paddingBottom="10px">
                            <Typography variant="bodyBold">Phone Name</Typography>
                            <Typography variant="bodyRegular"> {customerData.customerDetails.phoneNumber.value} </Typography>
                        </Grid>
                        {customerData.customerDetails.alternatePhoneNumber.value !== '' && (
                            <Grid container={true} flexDirection="column" paddingBottom="10px">
                                <Typography variant="bodyBold">Alternate Phone Number</Typography>
                                <Typography variant="bodyRegular"> {customerData.customerDetails.alternatePhoneNumber.value} </Typography>
                            </Grid>
                        )}
                        {customerData.customerDetails.email.value !== '' && (
                            <Grid container={true} flexDirection="column" paddingBottom="10px">
                                <Typography variant="bodyBold">Email</Typography>
                                <Typography variant="bodyRegular"> {customerData.customerDetails.email.value} </Typography>
                            </Grid>
                        )}
                        <Grid container={true} flexDirection="column" paddingBottom="10px">
                            <Typography variant="bodyBold">Dog on the property?</Typography>
                            <Typography variant="bodyRegular"> {customerData.customerDetails.isDog.value} </Typography>
                        </Grid>
                    </Grid>
                )} */}
            </Card>
        </Box>
    );
};

export default CustomerInformationSummary;
